<template>
  <div>
    <div class="flex mt-8">
      <div class="w-1/2 border px-4 py-4 mb-4 -mr-2 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Total Sales</h1>
          </div>
        </div>
        <div class="flex text-xl mt-2 text-gray-600">
          GMD {{ formatPrice(data.user.totalsales) }}
        </div>
      </div>
      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Sales Today</h1>
          </div>
        </div>
        <div class="flex text-xl mt-2 text-gray-600">
          GMD {{ formatPrice(data.user.transactions) }}
        </div>
      </div>
      <div class="w-1/2 border px-4 bg-gray-100 py-4 mb-4 ml-4 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Total Shortage</h1>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex text-xl mt-2 text-gray-600">
            GMD {{ formatPrice(data.total_shortage) }}
          </div>

          <div class="flex text-sm mt-2 text-gray-600">
            <button
              v-if="data.total_shortage > 0"
              @click="showConfirmModal()"
              class="text-blue-600 hover:text-blue-500"
            >
              Repay Shortage
            </button>
          </div>
        </div>
      </div>
    </div>

    <shortage-repayment
      v-show="isModalVisible"
      modalHeadline="Please confirm?"
      :data="data"
      :confirmMessage="`Are you sure you want to make a deposit of`"
      @confirmEvent="confirmRequest"
      @close="closeConfirmModal"
    />

    <div v-if="stats" class="border p-4 rounded">
      <staff-sales :stats="stats" />
    </div>
  </div>
</template>

<script>
import StaffSales from "@/components/Charts/StaffSales.vue";
import ShortageRepayment from "../../../../components/ShortageRepayment.vue";
import FormatPrice from "@/mixins/FormatPrice";

export default {
  props: ["data", "stats"],

  mixins: [FormatPrice],

  components: { StaffSales, ShortageRepayment },

  data: () => ({
    isModalVisible: false,
    isLoading: false,
  }),

  methods: {
    showConfirmModal() {
      this.isModalVisible = true;
    },
    closeConfirmModal() {
      console.log("Emmit complete");

      this.$emit("reload");
      this.isModalVisible = false;
      this.$router.go(-1);
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        // this.onSubmit();
      }
    },
  },
};
</script>